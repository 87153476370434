.fsm-sidebar-root {
  /* height: 100vh; */
  position: fixed;
  width: 100%;
  display: flex;
  background: #000000;
  flex-direction: column;
  background: #3E106E 0% 0% no-repeat padding-box;
}

.fsm-sidebar-header {
  margin-top: 1vh;
  height: 8vh;
  padding: 0 4%;
  display: flex;
  font: normal normal bold 18px/21px Arial;
  letter-spacing: 0px;
  color: #E4DDFC;
  opacity: 1;
  /* identical to box height */
  align-items: center;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(90deg, #AC23F5 0%, #6233FE 72.4%, #6233FE 100%);
  border-image-slice: 1;
}

/* .fsm-sidebar-content {
  height: 82vh;
  display: flex;
  padding: 0% 4%;
  box-sizing: border-box;
  flex-direction: column;
} */
.fsm-sidebar-headerdata {
  font-size: 14px;
  font-weight: normal;
  margin-left: 8px;
}
.fsm-sidebar-footer {
  height: 6vh;
  display: flex;
  padding: 0% 4%;
  margin: 0.5vh 0 2.7vh 0;
  font: normal normal bold 12px/18px Arial;
  flex-direction: column;
  box-sizing: border-box;
  letter-spacing: 0px;
  color: #E4DDFC;
  opacity: 1;
}

.fsm-sidebar-content-heading {
  font: normal normal bold 16px/18px Arial;
  line-height: 19px;
  margin: 3vh 0 1.5vh 1vh;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.fsm-sidebar-content-faqs {
  overflow-y: auto;
}

.fsm-sidebar-content-faq-root {
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
}

.fsm-sidebar-content-faq-ques-root {
  width: 100%;
  display: flex;
  padding: 1.5vh 0;
  box-sizing: border-box;
}

.fsm-sidebar-content-faq-ques {
  width: 90%;
  font: normal normal normal 14px/21px Arial;
  letter-spacing: 0px;
  color: #E4DDFC;
  opacity: 1;
  line-height: 19px;
  margin-left: 3%;
  margin-right: 3%;
}

.fsm-sidebar-content-faq-icon {
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

.fsm-sidebar-content-faq-ans {
  width: 100%;
  font: normal normal normal 14px/21px Arial;
  letter-spacing: 0px;
  color: #E4DDFC;
  opacity: 1;
  line-height: 19px;
  white-space: pre-wrap;
  margin: 1.5vh 0 1.5vh 0;
  padding-left: 3%;
  box-sizing: border-box;
}

.fsm-sidebar-question {
  margin: 5% 0%;
  cursor: pointer;
  font-size: 16px;
}

.fsm-sidebar-chat-icon {
  margin-right: 10px;
}

.fsm-sidebar-faq-loader {
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fsm-sidebar-datasource-list {
  padding-left: 3%;
  box-sizing: border-box;
  letter-spacing: 0px;
  color: #E4DDFC;
  font: normal normal 12px/18px Arial;
}
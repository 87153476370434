.login-root {
  display: flex;
  margin: 0 auto;
  max-width: 444px;
  flex-direction: column;
}

.login-header {
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-content {
  height: 65vh;
  display: flex;
  flex-direction: column;
}

.login-content-inner {
  padding: 5%;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #E4E4E4;
}

.login-content-heading {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2vh;
}

.login-content-error {
  display: flex;
  color: #ed6c02;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vh !important;
}

.login-content-button {
  display: flex;
  font-weight: bold;
  justify-content: center;
}

.login-footer {
  height: 10vh;
  text-align: center;
}
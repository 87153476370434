.fsm-chat-root {
  height: 82.5vh;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}

.fsm-chat-header {
  height: 7vh;
  display: flex;
  justify-content: center;
  background-color: #F6F8F2;
}

.fsm-chat-header-box {
  width: 75%;
  display: flex;
  font-size: 16px;
  color: #808080;
  font-style: italic;
  align-items: center;
}

.fsm-chat-header-left {
  width: 97%;
}

.fsm-chat-header-right {
  width: 3%;
  text-align: flex-end;
}

.fsm-chat-content {
  padding: 0%;
  display: flex;
  overflow-y: auto;
  box-sizing: border-box;
  flex-direction: column;
}

.fsm-chat-content-normal {
  height: 85vh;
  margin-top: 8px;
}

.fsm-chat-content-botloader {
  height: 83vh;
}

.fsm-chat-content-suggestion {
  height: 70vh;
}

.fsm-chat-footer {
  height: 17vh;
  /* display: flex; */
  align-items: center;
  background: #F6F8F2 0% 0% no-repeat padding-box;
  box-sizing: border-box;
  justify-content: center;
}
.fsm-chat-footer-wrapper
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
 
}
.fsm-chat-footer-box-container {
  /* width: 75%; */
  display: flex;
  flex-direction: row;
}

.fsm-chat-footer-box {
    width: 75%;
    height: 52px;
    display: flex;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000001A;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    opacity: 1;
    flex-direction: row;
}

.fsm-chat-footer-box-left {
  width: 95%;
  display: flex;
}

.fsm-chat-footer-box-right {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fsm-chat-footer-container-msg {
  margin-top: 1vh;
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0em;
  color: #333333;
  font: normal normal normal 12px/14px Arial;
  padding-bottom: 10px;
}

.divider-container {
  display: flex;
  margin: 0.5vh 0;
  justify-content: center;
}

.divider-border {
  width: 100%;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.divider-content {
  /* width: 25%; */
  display: flex;
  padding: 0 16px;
  color: #1d1c1d;
  line-height: 27px;
  border-radius: 24px;
  justify-content: center;
  background-color: #CFC1FC;
}

.load-loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-more-loader {
  margin: 0 auto;
}

.refreshing-session-loader {
  display: block;
  margin: 0 auto;
  z-index: 1000;
  position: absolute;
  margin-left: 50%;
  margin-right: 50%;
  top: 50%;
  width: 100vw;
}

.refreshing-session-loader-overlay {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: 0.75;
  position: absolute;
  background: black;
}

.refreshing-session-loader-text {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.bot-loader-root {
  width: 100%;
  height: 2vh;
  display: flex;
  justify-content: center;
}

.bot-loader-inner {
  width: 75%;
}

.suggestion-root {
  width: 100%;
  height: 18vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.suggestion-inner {
  width: 75%;
}

.suggestion-text-root {
  display: flex;
  margin: 0.5vh 0;
  cursor: pointer;
  justify-content: flex-end;
}

.suggestion-text {
  padding: 10px;
  font-size: 16px;
  color: #6233FE;
  font-weight: 400;
  line-height: 21px;
  box-sizing: border-box;
  border: 1px solid #6233FE;
}

.fsm-chat-footer-link {
  margin-left: 6px;
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

.link {
  margin-left: 6px;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}